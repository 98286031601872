<template>
    <dialog ref="preferences" class="modal">
        <form method="dialog" class="modal-box" style="max-width: 42rem">
            <h3 class="text-lg font-bold">
                Customize Consent Preferences
            </h3>
            <p>
                We use cookies to help you navigate efficiently and perform certain functions. You will find detailed
                information about all cookies under each consent category below. The cookies that are categorized as
                "Necessary" are stored on your browser as they are essential for enabling the basic functionalities of
                the site.
                <br>
                We also use third-party cookies that help us analyze how you use this website and to store your
                preferences. You can choose to enable or disable some or all
                of these cookies but disabling some of them may affect your browsing experience.
            </p>
            <div class="divide-y divide-base-200/40">
                <div class="form-control">
                    <label class="label cursor-not-allowed justify-start gap-2">
                        <input type="checkbox"
                               class="toggle"
                               checked
                               name="necessary"
                               disabled>
                        <span class="label-text text-lg">Necessary</span>
                    </label>

                    <div class="space-y-2">
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="cookie-consent">View cookie purpose.</label>
                            <input id="cookie-consent" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">cookie-consent</pre>
                            </div>
                            <div class="collapse-content">
                                <p><b class="text-primary">Purpose:</b> Remembers the user's consent choice.</p>
                                <p>
                                    <b class="text-primary">Duration:</b> 6 or 12 months <small
                                        class="text-base-content/50">(depending on consent)</small>
                                </p>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="xsrf-token">View cookie purpose.</label>
                            <input id="xsrf-token" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">XSRF-TOKEN</pre>
                            </div>
                            <div class="collapse-content">
                                <p><b class="text-primary">Purpose:</b> Used for security reasons.</p>
                                <p><b class="text-primary">Duration:</b> 2 hours</p>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="cdc_session">View cookie purpose.</label>
                            <input id="cdc_session" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">cdc_session</pre>
                            </div>
                            <div class="collapse-content">
                                <p><b class="text-primary">Purpose:</b> To identify the session instance for a user.</p>
                                <p><b class="text-primary">Duration:</b> 2 hours</p>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="id">View cookie purpose.</label>
                            <input id="id" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">&lt;id&gt;</pre>
                            </div>
                            <div class="collapse-content">
                                <p>
                                    <b class="text-primary">Purpose:</b> To store user input data temporarily for the
                                    user's convenience. Name is a 40 characters long randomised text in order to protect
                                    against session fixation attacks.
                                </p>
                                <p><b class="text-primary">Duration:</b> 2 hours</p>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="recaptha">View cookie purpose.</label>
                            <input id="recaptha" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">_GRECAPTCHA</pre>
                            </div>
                            <div class="collapse-content">
                                <p>
                                    <b class="text-primary">Purpose:</b> Google Recaptcha assesses user behavior on this
                                    website, collecting various software and hardware factors to be sent for analysis to
                                    Google to prevent systems cyber spam and abuse from bots.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="hasAnalytics" class="form-control">
                    <label class="label justify-start gap-2">
                        <input :checked="hasConsent"
                               type="checkbox"
                               class="toggle"
                               name="analytics"
                               @change="setConsent(!hasConsent)">
                        <span class="label-text text-lg">Analytics</span>
                    </label>

                    <div class="space-y-2">
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="google-analytics">View cookie purpose.</label>
                            <input id="google-analytics" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">_ga</pre>
                            </div>
                            <div class="collapse-content">
                                <p>
                                    <b class="text-primary">Purpose:</b> The _ga cookie, installed by Google Analytics,
                                    calculates visitor, session, campaign data, and also keeps track of site usage for
                                    the site's analytics report. The cookie stores information anonymously and assigns a
                                    randomly generated number to recognize unique visitors..
                                </p>
                                <p><b class="text-primary">Duration:</b> 2 years</p>
                            </div>
                        </div>
                        <div class="collapse collapse-arrow bg-base-200">
                            <label class="sr-only" for="google-analytics-id">View cookie purpose.</label>
                            <input id="google-analytics-id" type="radio" name="details">
                            <div class="collapse-title text-lg font-medium">
                                Cookie:
                                <pre class="inline-block">_ga_&lt;id&gt;</pre>
                            </div>
                            <div class="collapse-content">
                                <p>
                                    <b class="text-primary">Purpose:</b> Installed by Google Analytics, _ga_&lt;id&gt;
                                    cookie stores information on how visitors use a website, while also creating an
                                    analytics report of the website's performance. Some of the data that are collected
                                    include the number of visitors, their source, and the pages they visit anonymously.
                                </p>
                                <p><b class="text-primary">Duration:</b> 2 years</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <form method="dialog" class="modal-backdrop">
            <button>close</button>
        </form>
    </dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useConsent } from '#imports';

export default defineComponent({
    name: 'CookiePreferences',

    setup: () => {
        const hasAnalytics = useRuntimeConfig().public.gtag.id;
        const {
            preferences,
            hasConsent,
            setConsent
        } = useConsent();

        return {
            hasAnalytics,
            preferences,
            hasConsent,
            setConsent
        };
    }
});
</script>
